import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { LoginService } from "src/app/services/login.service";
import { StorageService } from "src/app/services/sotarage.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  public show: boolean = false;
  public showcn: boolean = false;

  public newPassword: FormGroup;
  currentUser: any;
  token: any;

  constructor(
    private fb: FormBuilder,
    public router: Router,
    private connexion: LoginService,
    private storage: StorageService,
    private routes: ActivatedRoute
  ) {
    this.newPassword = this.fb.group({
      password: ["", Validators.required],
      cnPpassword: ["", Validators.required],
    });
  }
  ngOnInit() {}

  resetPassword() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "",
        cancelButton: "",
      },
      heightAuto: false,
    });
    this.token = this.routes.snapshot.params["token"];
    const password = this.newPassword.value["password"];
    this.connexion.newPassword(this.token, password).subscribe((data) => {
      if (data.status) {
          let timerInterval = 2000;
          Swal.fire({
            position: 'center',
            text: data.message,
            title: "Changement du mot de passe",
            icon: 'success',
            heightAuto: false,
            showConfirmButton: false,
            confirmButtonColor: '#0857b5',
            showDenyButton: false,
            showCancelButton: false,
            allowOutsideClick: false,
            timer: timerInterval,
            timerProgressBar: true,
          }).then(() => {
             //REDIRECTION ET DECONNECTION APRES LE CHANGEMENT DE MOT DE PASSE
             this.connexion.logout().subscribe({
              next: (res) => {
                this.storage.clean();
                this.router.navigateByUrl("/auth/login");
              },
              error: (err) => {
              },
            });
          });
        } else {
          Swal.fire({
            position: 'center',
            text: data.message,
            title: 'Erreur',
            icon: 'error',
            heightAuto: false,
            showConfirmButton: true,
            confirmButtonText: 'OK',
            confirmButtonColor: '#0857b5',
            showDenyButton: false,
            showCancelButton: false,
            allowOutsideClick: false,
          }).then((result) => { });
        }
    },(error) => {
      console.error('Erreur lors de la connexion :', error);
      const errorMessage = error.error && error.error.message ? error.error.message : 'Erreur inconnue';

    });
  }
  showPassword() {
    this.show = !this.show;
  }
  showCnPassword() {
    this.showcn = !this.showcn;
  }
}
