<div class="page-wrapper">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">
        <div class="login-card">
          <div>
            <div>
              <a class="logo" routerLink="/">
                <img
                  class="img-fluid for-light"
                  src="assets/images/logo/sossara.png"
                  alt="looginpage"
                  style="   max-width: 50%;"
                />
                <img
                  class="img-fluid for-dark"
                  src="assets/images/logo/sossara_dark.png"
                  alt="looginpage"
                  style="   max-width: 50%;"
                />
              </a>
            </div>
            <div class="login-main">
              <form class="theme-form" [formGroup]="resetForm">
                <h4>Modifier son mot de passe</h4>
                <p>Saisez votre email et mot passe</p>
                <div class="form-group">
                  <label class="col-form-label">Adresse email</label>
                  <input
                    class="form-control"
                    type="email"
                    required=""
                    placeholder="app@yourmail.com"
                    formControlName="email"
                  />
                  <div
                    *ngIf="
                      resetForm.controls.email.touched &&
                      resetForm.controls.email.errors?.required
                    "
                    class="text text-danger mt-1"
                  >
                    Email est obligatoire
                  </div>
                  <div
                    *ngIf="
                      resetForm.controls.email.touched &&
                      resetForm.controls.email.errors?.email
                    "
                    class="text text-danger mt-1"
                  >
                    Adresse email invalide
                  </div>
                </div>
                <div class="text-end mt-3">
                  <button
                    class="btn monBlog d-block w-100"
                    [disabled]="!resetForm.valid"
                    (click)="sendMail()"
                    type="button"
                  >
                    <span>Envoyer</span>
                  </button>
                </div>
              </form>
              <!-- <h4>Reset Your Password</h4> -->
              <!-- <div class="form-group">
                  <label class="col-form-label">Enter Your Mobile Number</label>
                  <div class="row">
                    <div class="col-md-3">
                      <input class="form-control mb-1" type="text" value="+ 91">
                    </div>
                    <div class="col-md-9">
                      <input class="form-control mb-1" type="tel" value="000-000-0000">
                    </div>
                    <div class="col-12">
                      <button class="btn btn-primary d-block w-100 m-t-10" type="submit">Send</button>
                    </div>
                  </div>
                </div> -->
              <!-- <div class="text-center mt-4 mb-4"><span class="reset-password-link">If don't receive OTP?  <a class="btn-link text-danger" routerLink='/'>Resend</a></span></div>
                <div class="form-group">
                  <label class="col-form-label pt-0">Enter OTP</label>
                  <div class="row">
                    <div class="col">
                      <input class="form-control text-center opt-text" type="text" value="00" maxlength="2">
                    </div>
                    <div class="col">
                      <input class="form-control text-center opt-text" type="text" value="00" maxlength="2">
                    </div>
                    <div class="col">
                      <input class="form-control text-center opt-text" type="text" value="00" maxlength="2">
                    </div>
                  </div>
                </div> -->
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
