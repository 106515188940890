import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import { StorageService } from './sotarage.service';


const env = environment.Url_BASE;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private http: HttpClient,private storageService: StorageService) { }
  getHeaders(): HttpHeaders {
    const token = this.storageService.getUser().token;
    return new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
  }
  private accessToken: string;

  setAccessToken(token: string) {
    this.accessToken = token;
  }


  login(email: string, password: string): Observable<any> {
    return this.http.post(
      env + '/auth/signin',
      {
        email,
        password,
      },
      httpOptions
    );
  }


  // forgotPassword(email: string): Observable<any> {
  //   return this.http.post(
  //     env + '/reset-password',
  //     {
  //       email
  //     },
  //     httpOptions
  //   );
  // }
    //ENVOIE D'EMAIL POUR CHANGER LE PASSWORD
    forgotPassword(email: string): Observable<any> {
      // const headers = this.getHeaders();
      const formData = new FormData();
      formData.append('email', email);
      return this.http.post(`${env}/auth/forgotPassword`,
        formData
      );
    }


  ChangerMotDePasse(oldPassword: any, newPassword: any): Observable<any> {
    const headers = this.getHeaders();
 
    const formData = new FormData();
    formData.append('oldPassword', oldPassword);
    formData.append('newPassword', newPassword);
    return this.http.post(
      env + '/auth/updatePassword',
      formData,
      { headers }
    );
  }

  //CHANGER PHOTO PROFILE
  changerPhoto(photo: File): Observable<any> {
    const headers = this.getHeaders();
    headers.set('Cache-Control', 'no-cache'); // Désactive le cache pour cette requête
    const formData = new FormData();
    formData.append('photo', photo);
    return this.http.put(`${env}/user/updatePhoto`, formData, { headers });
  }

  //MODIFIER PROFIL USER
  modifierProfil(
    nom: string,
    telephone: string,
    email: string,
    dateNaissance: string
  ): Observable<any> {
    const formData = new FormData();
    const headers = this.getHeaders();
    formData.append('nom', nom);
    formData.append('email', email);
    formData.append('telephone', telephone);
    formData.append('dateNaissance', dateNaissance);
    return this.http.put(
      env + '/user/updateProfil',
      formData,
      { headers }
    );
  }



    //CHANGER PASSWORD APRES OUBLIE
    newPassword(token: string, newPassword: any): Observable<any> {
      const headers = this.getHeaders();
      const formData = new FormData();
      formData.append('token', token);
 
      formData.append('newPassword', newPassword);
      return this.http.post(env + '/auth/resetPassword', formData);
    }



  
  register(
    nom: string,
    email: string,
    nomAgence: string,
    emailAgence: string,
    telephone: string,
    dateNaissance: string,
    nomDoc: string,
    numDoc: string,
    role: string,
    nomPhoto: any
  ): Observable<any> {
    const password ="password@3003A"
    const formData = new FormData();
    formData.append('nom', nom);
    formData.append('email', email);
    formData.append('nomAgence', nomAgence);
    formData.append('emailAgence', emailAgence);
    formData.append('password', password);
    formData.append('telephone', telephone);
    formData.append('dateNaissance', dateNaissance);
    formData.append('nomDoc', nomDoc);
    formData.append('numDoc', numDoc);
    const roles = [role, 'userRole'];
    formData.append('role[]', roles[0]);
    formData.append('role[]', roles[1]);
    // Suppose que role est un tableau
    // const roles = ['userRole'];

    // roles.forEach(role => {
    //   formData.append('role', role);
    // });
    formData.append('nomPhoto', nomPhoto as Blob);
    // Définition du header Content-Type
    const headers = this.getHeaders();

    // Utilisation des options pour ajouter les headers
    const options = { headers: headers };

    return this.http.post(
      env + '/auth/addalluser',
      formData,
      {headers}
    );
  }

  logout(): Observable<any> {
    const token = this.storageService.getUser().token;
    const req = new HttpRequest('POST', env + `signout/${token}`, {}, httpOptions);
    return this.http.request(req);
  }

    //AFFICHER LES INFORMATIONS DE USER CONNECTER
    AfficherUserConnecter(): Observable<any> {
      const headers = this.getHeaders();
  
      return this.http.get(`${env}/user/afficherConnecter`, { headers });
    }

    getUnreadNotificationsCount(): Observable<any> {
      const headers = this.getHeaders();
      return this.http.get(`${env}/notifications/countUnread`, { headers });
    }
  
      //AFFICHER LA LISTE DES NOTIFICATIONS DE USER CONNECTE
  AfficherListeNotification(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(`${env}/notifications/get`, { headers });
  }

}


