import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/sotarage.service';

@Component({
  selector: 'app-swiper',
  templateUrl: './swiper.component.html',
  styleUrls: ['./swiper.component.scss']
})
export class SwiperComponent implements OnInit {
  currentUser: any;

  constructor(private storage:StorageService,){}
  ngOnInit(): void {
    this.currentUser = this.storage.recupererUser();

  }

}
