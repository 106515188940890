import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable, Subject, of } from "rxjs";
import { catchError, switchMap, takeUntil } from "rxjs/operators";
import { LoginService } from "src/app/services/login.service";
import { StorageService } from "src/app/services/sotarage.service";

@Injectable({
  providedIn: "root",
})
export class AdminGuard implements CanActivate {
  profil: any;
  users: any;
  private destroy$ = new Subject<void>();

  constructor(
    private storage: StorageService,
    public router: Router,
    private serviceUser: LoginService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.serviceUser.AfficherUserConnecter().pipe(
      takeUntil(this.destroy$),
      switchMap((data) => {
        this.users = data[0];
        this.profil = this.users?.profil;

        if (!this.users || !this.profil) {
          this.router.navigate(["/auth/login"]);
          return of(false);
        }

        if (this.profil === "ADMIN" || this.profil === "SUPERADMIN") {
          if (state.url === "/dashboard/admin" && this.profil === "SUPERADMIN") {
            this.router.navigate(["/dashboard"]);
            return of(false);
          }

          if (state.url === "/dashboard" && this.profil !== "SUPERADMIN") {
            this.router.navigate(["/dashboard/admin"]);
            return of(false);
          }

          if (state.url === "/biens/register" && this.profil !== "SUPERADMIN") {
            this.router.navigate(["/dashboard/admin"]);
            return of(false);
          }

          if (
            (this.profil === "ADMIN" || this.profil === "SUPERADMIN") &&
            ["/auth/login", "/auth/reset-password/", "/auth/activate-account/", "/auth/mdp-oublie"].some((url) =>
              state.url.startsWith(url)
            )
          ) {
            this.router.navigate(["/adresse/pays"]);
            return of(false);
          }

          return of(true);
        } else {
          this.router.navigate(["/auth/login"]);
          return of(false);
        }
      }),
      catchError(() => {
        this.router.navigate(["/auth/login"]);
        return of(false);
      })
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
