import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "dashboard",
    loadChildren: () =>
      import("../../Composants/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: "confidentialite",
    loadChildren: () =>
      import("../../Composants/apps/confidentialite/confidentialite.module").then(
        (m) => m.ConfidentialiteModule
      ),
  },
  {
    path: "terme",
    loadChildren: () =>
      import("../../Composants/apps/termes/termes.module").then(
        (m) => m.TermesModule
      ),
  },
  {
    path: "widgets",
    loadChildren: () =>
      import("../../Composants/widgets/widgets.module").then(
        (m) => m.WidgetsModule
      ),
  },

  {
    path: "project",
    loadChildren: () =>
      import("../../Composants/apps/project/project.module").then(
        (m) => m.ProjectModule
      ),
  },
  {
    path: "biens",
    loadChildren: () =>
      import("../../Composants/apps/Biens/biens.module").then(
        (m) => m.BiensModule
      ),
  },

  {
    path: "user",
    loadChildren: () =>
      import("../../Composants/apps/agences/agences.module").then(
        (m) => m.AgencesModule
      ),
  },
  {
    path: "blog",
    loadChildren: () =>
      import("../../Composants/apps/Blog/blogs.module").then(
        (m) => m.BlogsModule
      ),
  },
  {
    path: "adresse",
    loadChildren: () =>
      import("../../Composants/apps/adresse/adresse.module").then(
        (m) => m.AdresseModule
      ),
  },
  {
    path: "faq",
    loadChildren: () =>
      import("../../Composants/apps/faq/faq.module").then(
        (m) => m.FaqModule
      ),
  },
  {
    path: "droits",
    loadChildren: () =>
      import("../../Composants/apps/Gestion des droits/gestionsDroits.module").then(
        (m) => m.GestionsDroitsModule
      ),
  },

{
  path: "gestionTransaction",
  loadChildren: () =>
    import("../../Composants/apps/Transaction & facture/gestionsTransaction.module").then(
      (m) => m.GestionsTransactionModule
    ),
},
  {
    path: "configuration",
    loadChildren: () =>
      import("../../Composants/apps/config/config.module").then(
        (m) => m.ConfigModule
      ),
  },
  {
    path: "abonnement",
    loadChildren: () =>
      import("../../Composants/apps/Abonnement/abonnement.module").then(
        (m) => m.AbonnementModule
      ),
  },

  {
    path: "chart",
    loadChildren: () =>
      import("../../Composants/charts/charts.module").then(
        (m) => m.ChartModule
      ),
  },

  {
    path: "form",
    loadChildren: () =>
      import("../../Composants/forms/forms.module").then((m) => m.FormModule),
  },
  {
    path: "users",
    loadChildren: () =>
      import("../../Composants/utilisateurs/utilisateurs.module").then((m) => m.UtilisateursModule),
  },
  {
    path: "cards",
    loadChildren: () =>
      import("../../Composants/cards/cards.module").then((m) => m.CardsModule),
  },

  {
    path: "gallery",
    loadChildren: () =>
      import("../../Composants/gallery/gallery.module").then(
        (m) => m.GalleryDemoModule
      ),
  },

  {
    path: "search-pages",
    loadChildren: () =>
      import("../../Composants/others/search-result/search-result.module").then(
        (m) => m.SearchResultModule
      ),
  },
  {
    path: "chats",
    loadChildren: () =>
      import("../../Composants/chat/chat.module").then(
        (m) => m.ChatModule
      ),
  },
  {
    path: "notifications/:section", // Correction de 'notificcations' à 'notifications' et ajout du paramètre ':section'
    loadChildren: () =>
      import("../../Composants/apps/notifications/notifications.module").then(
        (m) => m.NotificationsModule
      ),
  },
  {
    path: "table", // Correction de 'notificcations' à 'notifications' et ajout du paramètre ':section'
    loadChildren: () =>
      import("../../Composants/table/table.module").then(
        (m) => m.TableModule
      ),
  }
  
];
