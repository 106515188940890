 <!-- error-404 start-->
 <div class="error-wrapper py-2">
    <div class="container"><img class="img-100" src="assets/images/other-images/sad.png" alt="">
      <!-- <i class="fa fa-trash"></i> -->
      <div class="error-heading">
        <h2 class="headline font-primary">404</h2>
      </div>
      <div class="col-md-8 offset-md-2">
        <p class="sub-content">Desolé cette page n'existe pas.</p>
      </div>
      <div class="mb-2"><a class="btn btn-primary btn-lg" routerLink=''>Retour  <img src="assets/images/logo/sossara_dark.png" alt=""></a></div>
    </div>
  </div>
  <!-- error-404 end      -->