import { Injectable } from '@angular/core';
import { ChatMessage } from '../model/chat-message';
import * as SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/sotarage.service';

const URL_BASE: string = environment.Url_BASE;

@Injectable({
  providedIn: 'root'
})
export class ChatserviceService {

  private stompClient: any;
  private messageSubject: BehaviorSubject<ChatMessage[]> = new BehaviorSubject<ChatMessage[]>([]);
  private unreadCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private unreadCountSubject3: BehaviorSubject<number> = new BehaviorSubject<number>(0);


  private isRdvDataRequired = false;
  private isConnected = false;
  private subscribedRooms: Set<string> = new Set();
  liste: any;

  constructor(private httpClient: HttpClient, private storageService: StorageService) {
    this.initConnectionSocket();
  }

  public getUnreadCount(): BehaviorSubject<number> {
    return this.unreadCountSubject;
  }
 // Méthode pour définir si les données RDV doivent être récupérées
 setRdvDataRequired(flag: boolean) {
  this.isRdvDataRequired = flag;
}

// Méthode pour vérifier si les données RDV doivent être récupérées
shouldFetchRdvData(): boolean {
  return this.isRdvDataRequired;
}

  public getUnreadCountListe(): BehaviorSubject<number> {
    return this.unreadCountSubject3;
  }

  // Méthode pour ajouter le token JWT aux en-têtes
  getHeaders(): HttpHeaders {
    const token = this.storageService.getUser().token;
    return new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
  }

  initConnectionSocket(): Promise<void> {
    return new Promise((resolve, reject) => {
      const socket = new SockJS(environment.socketUrl);
      this.stompClient = Stomp.over(socket);
      this.stompClient.connect({}, () => {
        this.isConnected = true;
        resolve();
      }, (error: any) => {
        reject(error);
      });
    });
  }

  joinRoom(roomId: string) {
    if (this.subscribedRooms.has(roomId)) {
      return;
    }
    this.initConnectionSocket().then(() => {
      this.stompClient.subscribe(`/topic/${roomId}`, (messages: any) => {
        const messageContent = JSON.parse(messages.body);
        const currentMessage = this.messageSubject.getValue();
        currentMessage.push(messageContent);
        this.messageSubject.next(currentMessage);

      });
      this.subscribedRooms.add(roomId);
      if (roomId) {
        this.loadMessage(roomId);
      }
    }).catch(error => {
      console.error('Failed to connect and subscribe', error);
    });
  }

  joinRoom2(uuid: string) {
    if (!this.isConnected) {
      console.error('STOMP client is not connected');
      return;
    }
    if (this.subscribedRooms.has(uuid)) {
      return;
    }
    this.initConnectionSocket().then(() => {
      this.stompClient.subscribe(`/topic/unread-count/${uuid}`, (message: any) => {
        const unreadCount = JSON.parse(message.body);
      
        this.unreadCountSubject.next(unreadCount);
      });
      this.subscribedRooms.add(uuid);
      this.AfficherListeNotification();
      if (uuid) {
        this.getUnreadNotificationsCount();
      }
    }).catch(error => {
      console.error('Failed to connect and subscribe', error);
    });
  }
  joinRoom3(uuid: string) {
    if (!this.isConnected) {
      console.error('STOMP client is not connected');
      return;
    }
    if (this.subscribedRooms.has(uuid)) {
      return;
    }
    this.initConnectionSocket().then(() => {
      this.stompClient.subscribe(`/topic/to-users/${uuid}`, (message: any) => {
        const unreadCount = JSON.parse(message.body);
        this.unreadCountSubject3.next(unreadCount);
      });
      this.subscribedRooms.add(uuid);
      this.AfficherListeNotification();
      if (uuid) {
        this.getUnreadNotificationsCount();
      }
    }).catch(error => {
      console.error('Failed to connect and subscribe', error);
    });
  }


  sendMessage(roomId: string, chatMessage: ChatMessage) {
    this.stompClient.send(`/app/chat/${roomId}`, {}, JSON.stringify(chatMessage));
  }

  getMessageSubject() {
    return this.messageSubject.asObservable();
  }

  loadMessage(roomId: string): void {
    if (roomId) {
      const headers = this.getHeaders();
      this.httpClient.get<any[]>(`${URL_BASE}/chat/${roomId}`, { headers }).pipe(
        map(result => {
          return result.map(res => {
            return {
              senderEmail: res.senderEmail,
              senderNom: res.senderNom,
              message: res.message,
              time: new Date(res.time)
            } as ChatMessage;
          });
        })
      ).subscribe({
        next: (chatMessage: ChatMessage[]) => {
          this.messageSubject.next(chatMessage);
        },
        error: (error) => {
          console.error(error);
        }
      });
    }
  }

  getChatByUuid(uuid: string): Observable<any> {
    return this.httpClient.get<any>(`${URL_BASE}/chat/message/chat/${uuid}`);
  }

  //AFFICHER LA LISTE DES CHATS EN FONCTION DE USER CONNECTE
  AfficherChatUserConnecte(): Observable<any> {
    const headers = this.getHeaders();
    return this.httpClient.get(`${URL_BASE}/chat/message/afficherchat`, { headers });
  }

  getUnreadNotificationsCount(): Observable<any> {
    const headers = this.getHeaders();
    return this.httpClient.get(`${URL_BASE}/notifications/countUnread`, { headers });
  }

  AfficherListeNotification(): Observable<any> {
    const headers = this.getHeaders();
    return this.httpClient.get(`${URL_BASE}/notifications/get`, { headers });
  }
  AfficherLaListeRdv(): Observable<any> {
    const headers = this.getHeaders();
    return this.httpClient.get(`${URL_BASE}/rdv/get/mine`, { headers });
  }
  AfficherLaListeRdvUserConnecte(): Observable<any> {
    const headers = this.getHeaders();
    return this.httpClient.get(`${URL_BASE}/rdv/get`, { headers });
  }


}
